import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap"
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div
        style={{
          transition: "color 0.5s ease-in-out, background 0.5s ease-in-out",
        }}
      >
        <Container fluid className="px-0 main"> 
          <Row noGutters className="justify-content-center">
            <Col>
              <Header siteTitle={data.site.siteMetadata.title} />
            </Col>
          </Row>
          {/* <Navbar pageInfo={pageInfo} /> */}
          <Row noGutters>
            <Col>
              <Container className="mt-5">
                <main>{children}</main>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container fluid className="px-0">
          <Row noGutters>
            <Col className="footer-col">
              <Footer auther="Samip Subedi" />
            </Col>
          </Row>
        </Container>
      </div>
    )}
  />
)

export default Layout
