import React from 'react';
import { Image } from 'react-bootstrap';
import {graphql, StaticQuery} from 'gatsby';
const Bio = props => {
    return (
      <StaticQuery
        query={graphql`
          query {
            allContentfulAuther {
              nodes {
                fullName
                bio {
                  bio
                }
                avatar {
                  file {
                    url
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const auther = data.allContentfulAuther.nodes[0]
          return (
            <div className="d-flex flex-row align-items-center">
              <Image
                src={auther.avatar.file.url}
                roundedCircle
                style={{
                  height: "80px",
                  width: "80px",
                }}
              />
              <p className="ml-3">{auther.bio.bio}</p>
              {/* <p className="ml-3">
                This blog is written by <strong>Samip Subedi</strong> who lives
                and works in Kathmandu building useful stuffs. I'm on twitter{" "}
                <a href="https://twitter.com/thessubedi" target="_blank" rel="noopener noreferrer">
                  @thessubedi
                </a>.
              </p> */}
            </div>
          )
        }}
      />
    )
}

export default Bio;
