import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import { FaMoon } from "react-icons/fa"
import { IoIosSunny } from "react-icons/io"

const Header = ({ siteTitle }) => (
  <header>
    <Container className="mt-5">
      <Row className="d-flex flex-row justify-content-between align-items-center">
        <Col>
          <h1 className="header-link">
            <Link to="/">Black Neuron</Link>
          </h1>
          {/* <h1>{siteTitle}</h1> */}
        </Col>
        <Col className="text-right">
          <ThemeToggler>
            {({ theme, toggleTheme }) => (
              <span
                title="Toggle dark mode"
                style={{
                  cursor: "pointer",
                }}
                onClick={e => {
                  toggleTheme(theme === "light" ? "dark" : "light")
                }}
              >
                {theme === "dark" ? (
                  <IoIosSunny
                    className="toggler-icon sun"
                    style={{
                      color: "rgb(256,256,0)",
                    }}
                  />
                ) : (
                  <FaMoon
                    className="toggler-icon moon"
                    style={{ fontSize: "20px" }}
                  />
                )}
              </span>
            )}
          </ThemeToggler>
        </Col>
      </Row>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
