import React from 'react';
import PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from 'gatsby';

const Footer = ({auther}) => {
    return (
      <footer className="pt-1">
        <Container>
          <Row>
            <Col className="d-flex flex-row justify-content-center">
              <Link to="/" className="mr-1">
                Home
              </Link>
              <span>&bull;</span>
              {/* <Link to="/about" className="mx-1">
                About
              </Link>
              <span>&bull;</span> */}
              <Link to="/contact" className="ml-1">
                Contact
              </Link>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <span>
                © {new Date().getFullYear() + ", " + auther}.
              </span>
            </Col>
          </Row>
        </Container>
      </footer>
    )
}
Footer.propTypes = {
  auther: PropTypes.string,
}

Footer.defaultProps = {
  auther: ``,
}
export default Footer;
